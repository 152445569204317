<template>
  <div class="">
    <!-- <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }"> -->
    <div
      class="block-icon-table-facture"
      @click.prevent="openModal"
      v-if="permission"
      title="Création Facture(s) Avoir(s)"
    >
      <font-awesome-icon icon="file-medical" />
    </div>
    <!-- </template> -->
    <!-- <span>Création Facture(s) Avoir(s)</span>
    </v-tooltip> -->

    <v-dialog
      v-model="dialog"
      max-width="1200px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            Création Facture(s) Avoir(s) N° : {{ item.num }}</v-label
          >
          <v-btn
            class="btn-close-header"
            icon
            @click="hideModal"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text">
          <v-form ref="form" lazy-validation>
            <v-progress-circular
              class="ml-2 mt-1"
              v-if="loading"
              indeterminate
              color="#704ad1"
            ></v-progress-circular>
            <div v-else>
              <v-row v-if="permissionDate" class="mt-4 mb-4">
                <v-col cols="12">
                  <v-menu :close-on-content-click="true" offset-y>
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="date"
                        label="Date de l'avoir"
                        outlined
                        readonly
                        hide-details
                        v-on="on"
                        :persistent-placeholder="true"
                        clearable
                        placeholder="Date"
                        prepend-inner-icon="mdi-calendar"
                        :autofocus="false"
                        color="#704ad1"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      locale="fr"
                      color="#704ad1"
                      item-color="#704ad1"
                    ></v-date-picker> </v-menu
                ></v-col>
              </v-row>
              <div
                v-for="(product, index) in gettersData"
                :key="'product' + index"
              >
                <v-row class="row-top">
                  <v-col cols xs="5" sm="5" md="5">
                    <v-text-field
                      v-model="product.nom"
                      disabled
                      outlined
                      dense
                      label="Nom"
                      color="#704ad1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols xs="2" sm="2" md="2">
                    <v-text-field
                      v-model="product.pu_ht"
                      type="number"
                      min="0"
                      required
                      :rules="[v => !!v || 'PU HT est obligatoire']"
                      class="msg-error"
                      validate-on-blur
                      dense
                      :persistent-placeholder="true"
                      label="PU HT"
                      :disabled="product.disabled || disabledQP"
                      outlined
                      color="#704ad1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols xs="2" sm="2" md="2">
                    <v-select
                      :items="listUnite"
                      v-model="product.unite"
                      label="Unité"
                      @change="checkConditionQuantite(product.id)"
                      :disabled="product.disabled || disabledQP"
                      item-text="name"
                      item-value="id"
                      class="msg-error"
                      validate-on-blur
                      outlined
                      dense
                      :persistent-placeholder="true"
                      :menu-props="{
                        bottom: true,
                        offsetY: true
                      }"
                      color="#704ad1"
                      item-color="#704ad1"
                    ></v-select>
                  </v-col>
                  <v-col cols xs="3" sm="3" md="3">
                    <v-text-field
                      v-model="product.qte_apres"
                      :value="product.qte_apres"
                      :max="product.qte_apres_avoir"
                      type="number"
                      dense
                      min="0"
                      outlined
                      :disabled="product.qte_disabled"
                      :persistent-placeholder="true"
                      label="Qté"
                      color="#704ad1"
                    ></v-text-field>
                    <span
                      class="warning-qte"
                      v-if="product.unite == '%' || disabledQP"
                    >
                      {{
                        `Rest qte à avoir ${Math.round(
                          product.qte_apres_avoir * 100
                        ) / 100}`
                      }}
                    </span>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div v-if="error" class="message-error-modal">
            <ul v-if="Array.isArray(error)" class="mb-0">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            text
            @click="handleCreateAvoir"
            :loading="loadingCreate"
            :disabled="computedDisabledButton"
            :class="{ loader: loadingCreate }"
          >
            Valider
          </v-btn>
          <v-btn text @click="hideModal">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  props: {
    permission: { required: true },
    item: { required: true },
    index: { required: true },
    functionCreate: { required: true },
    gettersData: { required: true },
    methodProduct: { required: true },
    disabledQP: { required: false },
    permissionDate: { required: true }
  },
  data() {
    return {
      date: null,
      dialog: false,
      rules: {
        required: value => !!value || 'champ obligatoire.',
        message: e => `Rest qte à avoir ${e}`,
        quantite: e =>
          `Quantité globale à avoir doit être infèrieur ou égal à ${e}`
      },
      listUnite: [
        { id: '', name: '' },
        { id: '%', name: '%' }
      ],
      loading: false,
      loadingCreate: false,
      error: [],
      disabledButton: false,
      qteGlobale: 0,
      qteGlobaleAvoir: 0,
      maxglobal: 0,
      unite: null
    };
  },
  computed: {
    computedDisabledButton() {
      let products = this.gettersData.filter(item => item.disabled == false);
      if (products.length == 0) {
        return true;
      }
      return false;
    },
    computedDisabledInputGlobale() {
      let products = this.gettersData.filter(item => item.disabled == false);
      if (products.length == 0) {
        return true;
      }
      return false;
    }
  },
  methods: {
    checkConditionQuantite(id) {
      this.gettersData.map(item => {
        if (item.id == id) {
          if (item.unite == '') {
            item.qte_apres = 1;
            item.qte_disabled = true;
          } else {
            item.qte_apres = 0;
            item.qte_disabled = false;
          }
        }
      });
    },
    async openModal() {
      let now = new Date();
      this.date = moment(now).format('YYYY-MM-DD');
      this.dialog = true;
      this.loading = true;
      const response = await this.methodProduct(this.item.id);
      if (response.success) {
        this.gettersData.map(item => {
          this.qteGlobaleAvoir =
            this.qteGlobaleAvoir + parseFloat(item.qte_apres_avoir);
        });
        this.unite = this.gettersData[0]?.unite;
        if (this.unite == '%') {
          this.maxglobal = 100;
        } else {
          this.maxglobal = parseFloat(this.qteGlobaleAvoir);
        }
        this.loading = false;
      }
    },
    changeQteAvoirProducts() {
      if (this.unite == '%') {
        let reductionGlobal = parseFloat(this.qteGlobale) / 100;
        if (parseFloat(this.qteGlobale) != 0) {
          if (parseFloat(this.qteGlobale) == 100) {
            this.gettersData.forEach(element => {
              element.qte_apres = parseFloat(element.qte_apres_avoir);
            });
          } else {
            this.gettersData.forEach(element => {
              element.qte_apres =
                parseFloat(element.qte_apres_avoir) -
                parseFloat(element.qte_apres_avoir) * reductionGlobal;
            });
          }
        } else {
          this.gettersData.forEach(element => {
            element.qte_apres = parseFloat(element.initial_apres_avoir);
          });
        }
      } else {
        if (this.qteGlobale == 0) {
          this.gettersData.forEach(element => {
            element.qte_apres = 0;
          });
        } else {
          if (this.qteGlobale < this.maxglobal) {
            let length = this.gettersData.length;
            let reduction = parseFloat(this.qteGlobale) / length;
            this.gettersData.forEach(element => {
              element.qte_apres = parseFloat(element.initial_apres_avoir);
              element.qte_apres =
                parseFloat(element.qte_apres_avoir) - reduction;
              element.qte_apres = element.qte_apres.toFixed(2);
            });
          } else {
            if (this.qteGlobale == this.maxglobal) {
              this.gettersData.forEach(element => {
                element.qte_apres = element.qte_apres_avoir;
              });
            }
          }
        }
      }
    },
    hideModal() {
      this.$refs.form.reset();
      this.dialog = false;
      this.error = [];
      this.loading = false;
      this.loadingCreate;
      this.disabledButton = false;
      this.qteGlobale = 0;
      this.qteGlobaleAvoir = 0;
      this.maxglobal = 0;
      this.unite = null;
    },
    async handleCreateAvoir() {
      if (this.$refs.form.validate()) {
        this.loadingCreate = true;
        let products = this.gettersData.filter(
          item => item.disabled == false && parseFloat(item.qte_apres) != 0
        );
        if (products.length == 0) {
          this.loadingCreate = false;
          this.error =
            'Aucune facture avoir à générer \n Les quantités à avoir doit être supèrieur à 0';
        } else {
          this.error = null;
          let bodyFormData = {
            vendeur: this.item.vendeur_id,
            id_facture: this.item.id,
            produits: products,
            date: this.date
          };
          const response = await this.functionCreate({
            item: this.item,
            bodyFormData: bodyFormData
          });
          if (response.success) {
            this.loadingCreate = false;
            this.dialog = false;
          } else {
            this.loadingCreate = false;
            this.error = response.error;
          }
        }
      }
    }
  },
  components: {}
};
</script>
